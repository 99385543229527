import { Outlet } from 'react-router-dom';

import { LayoutContextProvider } from 'src/contexts/LayoutContext';

import { StyledBaseLayout } from './styles';

const BaseLayout = () => (
  <LayoutContextProvider>
    <StyledBaseLayout>
      <Outlet />
    </StyledBaseLayout>
  </LayoutContextProvider>
);

export default BaseLayout;

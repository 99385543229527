import { Stack, styled } from '@mui/material';

export const StyledBaseLayout = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  gap: theme.spacing(2),
  flexDirection: 'column',
  overflow: 'auto',
  padding: 0,
  margin: 0,
  flex: 1,
  height: '100vh'
}));

export const StyledBaseLayoutTauron = styled(StyledBaseLayout)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100]
}));

export const StyledContentContainer = styled('div')(({ theme }) => ({
  padding: 0,
  flex: 1,
  '> div': {
    backgroundColor: theme.palette.grey[50]
  },
  [theme.breakpoints.up('tablet')]: {
    marginTop: theme.spacing(6),
    '> form': {
      height: '100%',
      '> div:not(.MuiAlert-root)': {
        height: '100%'
      }
    }
  },

  [theme.breakpoints.only('mobile')]: {},
  '> *': {}
}));

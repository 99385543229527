import { Stack, styled, Typography } from '@mui/material';

export const StyledMainLayout = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  height: '100%',
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  padding: 0,
  margin: 0
}));

export const StyledMainLayoutContent = styled('div')(({ theme }) => ({
  width: '100%',
  padding: 0,
  [theme.breakpoints.only('tablet')]: {
    width: '100%'
  },
  [theme.breakpoints.only('mobile')]: {
    marginTop: theme.spacing(2),
    width: '100%'
  }
}));

export const StyledPageLayout = styled(Stack)(() => ({
  justifyContent: 'center',
  gap: 0,
  padding: 0,
  flex: 1,
  margin: 0
}));

export const StyledIframe = styled('iframe')(() => ({
  height: '500px',
  flex: 1,
  display: 'flex',
  border: 0,
  overflow: 'hidden',
  padding: 0,
  boxShadow: 'inset 0px 5px 20px #CFD6E0'
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 600,
  color: theme.palette.custom.neutral[900],
  marginBottom: theme.spacing(1),
  maxWidth: theme.spacing(85)
}));

export const StyledSubtitle = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.custom.textColors[700],
  [theme.breakpoints.only('mobile')]: {
    fontSize: theme.typography.subtitle2.fontSize
  }
}));

export const StyledHeader = styled(Typography)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: theme.spacing(6),
  [theme.breakpoints.only('mobile')]: {
    marginBottom: theme.spacing(3)
  },
  h1: {
    [theme.breakpoints.only('mobile')]: {
      fontSize: '26px'
    }
  }
}));

import AppConfiguration from 'src/types/appConfiguration';

import palette from './palette';

export default {
  palette,
  envPrefix: '1',
  stageDisplay: false,
  feedbackEmail: 'support@billongroup.com',
  pocUrl:
    'https://teczkapoc.demo.tdm.bybillon.com/mojeDokumenty/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwdWJDaWYiOiJYRCJ9.wnULLfvoe7AlPP77GAQv7xVgZIY0Z7tQR59kkHAHJ3c'
} as AppConfiguration;

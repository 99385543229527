import { Components, Theme } from '@mui/material';

const componentConfiguration: Components<Theme>['MuiStepIcon'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      width: '50px',
      height: '50px',
      color: 'transparent',
      '&.Mui-active': {
        color: theme.palette.custom.primary[200],
        border: `1px solid ${theme.palette.primary.main}`,
        '& text': {
          fill: theme.palette.primary.main
        }
      },
      '&.Mui-completed': {
        color: theme.palette.custom.neutral[500],
        border: `1px solid ${theme.palette.custom.neutral[500]}`,
        '& text': {
          fill: theme.palette.primary.main
        }
      },
      borderRadius: '50%',
      border: `1px solid ${theme.palette.custom.neutral[500]}`
    }),
    text: ({ theme }) => ({
      fill: theme.palette.custom.neutral[500],
      fontWeight: 700
    })
  }
};
export default componentConfiguration;

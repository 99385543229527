import { ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import theme from 'src/constants/theme';

import ApiCheck from './components/apiCheck';
import createResponsiveFonts from './constants/theme/utils';
import './fonts';

import './App.css';

createResponsiveFonts(theme);

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ApiCheck />
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;

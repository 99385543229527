import AppConfiguration from 'src/types/appConfiguration';

import mvpConfig from './mvp/dev';

export enum AppName {
  MVP = 'MVP'
}

export const AppConfigMapping: {
  [k in AppName]: AppConfiguration;
} = {
  [AppName.MVP]: mvpConfig
};

const appName = 'MVP' as AppName;
export const appConfig = AppConfigMapping[appName];

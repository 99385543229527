import { Typography } from '@mui/material';

import MyTauronMenu from 'src/modules/default/components/MyTauronMenu/MyTauronMenu';
import { appConfig } from 'src/constants/appConfig';

import { StyledIframe, StyledPageLayout } from './styles';

const MainPage = () => (
  <StyledPageLayout>
    <MyTauronMenu />
    <StyledIframe src={appConfig?.pocUrl} />
    {false && (
      <>
        <br />
        <Typography textAlign="center">{`mt v ${process.env.REACT_APP_VERSION}`}</Typography>
      </>
    )}
  </StyledPageLayout>
);

export default MainPage;

import { useMediaQuery } from '@mui/material';

import brandLogo from 'src/assets/logo.jpg';
import theme from 'src/constants/theme';
import { ReactComponent as MenuIcon } from 'src/assets/menuIcon.svg';

import {
  StyledButton,
  StyledImg,
  StyledOptions,
  StyledRow,
  StyledToggleButton
} from './styles';

const MyTauronMenu = () => {
  const isTablet = useMediaQuery(theme.breakpoints.up('tablet'));

  const names = !isTablet
    ? [{ name: 'Moje Dokumenty', variant: isTablet ? 'contained' : 'text' }]
    : [
        { name: 'Moje Dokumenty', variant: 'contained' },
        { name: 'Faktury i wpłaty', variant: 'text' },
        { name: 'Zużycie energii', variant: 'text' },
        { name: 'Wiadomości', variant: 'text' },
        { name: 'Umowa i dane', variant: 'text' },
        { name: 'Pomoc', variant: 'text' }
      ];

  return (
    <StyledRow boxShadow={1} direction="row">
      <StyledImg src={brandLogo} alt="brandLogo" />
      <StyledOptions>
        {names.map(({ name, variant }) => (
          <StyledButton
            variant={variant === 'contained' ? 'contained' : 'text'}
          >
            {name}
          </StyledButton>
        ))}
        {!isTablet && (
          <StyledToggleButton>
            <MenuIcon />
          </StyledToggleButton>
        )}
      </StyledOptions>
    </StyledRow>
  );
};

export default MyTauronMenu;

const routes = {
  index: '/',
  publication: '/publication',
  publicationStatus: '/publication-status',
  publicationStatusId: '/publication-status/:jobId',
  initialization: '/initialization',
  initializationAddress: '/initialization/:address',
  request: '/request',
  requestL2appId: '/request/:l2AppId'
};

export default routes;

import { Components, Theme } from '@mui/material';

const componentConfiguration: Components<Theme>['MuiStep'] = {
  styleOverrides: {
    horizontal: ({ theme }) => ({
      [theme.breakpoints.down('desktop')]: {
        alignSelf: 'start'
      },
      [theme.breakpoints.only('mobile')]: {
        flex: 0
      }
    }),
    root: ({ theme }) => ({
      padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
      '&:hover': {
        backgroundColor: theme.palette.custom.primary[200],
        borderRadius: '8px'
      }
    })
  }
};

export default componentConfiguration;

import { Components, Theme } from '@mui/material';

const componentConfiguration: Components<Theme>['MuiStepConnector'] = {
  styleOverrides: {
    lineVertical: ({ theme }) => ({
      height: '100%',
      marginLeft: theme.spacing(2.5)
    })
  }
};

export default componentConfiguration;

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route
} from 'react-router-dom';

import RootPage from 'src/pages';
import routes from 'src/constants/routes';
import BaseLayout from 'src/components/baseLayout';
import ServiceOutage from 'src/components/serviceOutage';
import { SuspenseLoader } from 'src/components/loader';
import Default404Page from 'src/pages/default404/404';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={routes.index} element={<BaseLayout />}>
      <Route index element={<RootPage />} />
      <Route path="*" element={<Default404Page />} />
    </Route>
  )
);

const routerWithServiceOutage = createBrowserRouter(
  createRoutesFromElements(
    <Route path={routes.index} element={<BaseLayout />}>
      <Route index element={<ServiceOutage />} />
      <Route path="*" element={<ServiceOutage />} />
    </Route>
  )
);
const routerWithLoader = createBrowserRouter(
  createRoutesFromElements(
    <Route path={routes.index} element={<BaseLayout />}>
      <Route index element={<SuspenseLoader />} />
      <Route path="*" element={<SuspenseLoader />} />
    </Route>
  )
);

export { router, routerWithServiceOutage, routerWithLoader };

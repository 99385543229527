import { RouterProvider } from 'react-router-dom';

import routers from 'src/lib/routers';

const ApiCheck = () => {
  const { router, routerWithLoader, routerWithServiceOutage } = routers;

  const data = { status: 'success' };
  const error = null;
  const isLoading = false;

  if (isLoading) {
    return <RouterProvider router={routerWithLoader} />;
  }

  if (error || data?.status === 'error') {
    return <RouterProvider router={routerWithServiceOutage} />;
  }

  return <RouterProvider router={router} />;
};

export default ApiCheck;

import { Components, Theme } from '@mui/material';

const componentConfiguration: Components<Theme>['MuiDivider'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.primary.main,
      borderBottomWidth: '1px'
    })
  }
};

export default componentConfiguration;

import { styled, Stack, Button, IconButton } from '@mui/material';

export const StyledRow = styled(Stack)(({ theme }) => ({
  backgroundColor: 'white',
  padding: theme.spacing(2),
  paddingRight: theme.spacing(3),
  alignItems: 'center',
  gap: theme.spacing(2),
  [theme.breakpoints.up('tablet')]: {
    gap: theme.spacing(5)
  },
  [theme.breakpoints.up('desktop')]: {
    gap: theme.spacing(10)
  },

  [theme.breakpoints.down('tablet')]: {
    justifyContent: 'space-between'
  }
}));

export const StyledImg = styled('img')(({ theme }) => ({
  [theme.breakpoints.up('tablet')]: {
    width: '100px'
  },
  [theme.breakpoints.up('desktop')]: {
    width: '150px'
  },
  [theme.breakpoints.down('tablet')]: {
    width: '50px'
  },
  height: 'auto'
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: '14px',
  padding: `${theme.spacing(1)} ${theme.spacing(1)}`,

  [theme.breakpoints.up('desktop')]: {
    height: '50px',
    fontSize: '16px',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`
  },
  [theme.breakpoints.only('mobile')]: {
    height: '35px'
  }
}));

export const StyledOptions = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  [theme.breakpoints.up('desktop')]: {
    gap: theme.spacing(5)
  },
  [theme.breakpoints.only('tablet')]: {
    gap: theme.spacing(2)
  },
  [theme.breakpoints.only('mobile')]: {
    gap: theme.spacing(1)
  }
}));

export const StyledToggleButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  margin: theme.spacing(0, 5, 0, 2),
  [theme.breakpoints.only('tablet')]: {
    marginRight: theme.spacing(2.5)
  },
  [theme.breakpoints.only('mobile')]: {
    width: theme.spacing(2.25),
    marginRight: theme.spacing(1)
  }
}));

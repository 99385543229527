import { Components, Theme } from '@mui/material';

const componentConfiguration: Components<Theme>['MuiStepper'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: theme.spacing(2),
      backgroundColor: 'transparent',
      border: 0,
      padding: '0 0 0 45px',
      [theme.breakpoints.down('desktop')]: {
        gap: theme.spacing(1)
      }
    })
  }
};

export default componentConfiguration;

import { Components, Theme } from '@mui/material';

const componentConfiguration: Components<Theme>['MuiStepLabel'] = {
  styleOverrides: {
    iconContainer: ({ theme }) => ({
      [theme.breakpoints.down('desktop')]: {
        padding: 0
      }
    }),
    vertical: ({ theme }) => ({
      gap: theme.spacing(2)
    }),
    horizontal: ({ theme }) => ({
      [theme.breakpoints.down('desktop')]: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2)
      }
    }),
    labelContainer: ({ theme }) => ({
      [theme.breakpoints.down('desktop')]: {}
    }),
    label: ({ theme }) => ({
      ...theme.typography.subtitle2,
      color: theme.palette.custom.textColors[900],
      '&.Mui-active': {
        ...theme.typography.subtitle2,
        fontWeight: 900,
        color: theme.palette.primary.main,
        [theme.breakpoints.down('desktop')]: {
          fontSize: theme.typography.caption2.fontSize
        }
      },
      [theme.breakpoints.down('desktop')]: {
        fontSize: theme.typography.caption2.fontSize
      }
    })
  }
};

export default componentConfiguration;
